body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.subscribe-button-wrapper {
  display: inline-block;
  transform: scale(1.3) translate(-30%, 0);
  transform-origin: 0 0; /* Ensure scaling starts from the top-left */
}

.App {
  text-align: center;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #333;
}

nav ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: color 0.3s ease;
}

nav ul li a:hover, nav ul li a.active {
  color: #ff0000;
}

.subscribe-btn {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
  background-color: #cc0000;
}

main {
  padding: 40px 20px;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  /* margin-bottom: 40px; */
  color: #ff0000;
}

.youtube-content-list {
  margin-top: 30px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  border-bottom: 1px solid #333;
}

.tabs button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-right: 20px;
}

.tabs button:hover, .tabs button.active {
  color: #ff0000;
  border-bottom: 2px solid #ff0000;
}

.episode-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.episode-item {
  border-bottom: 1px solid #333;
  padding-bottom: 30px;
}

.episode-date {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.episode-item h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: #ffffff;
}

.episode-item p {
  font-size: 16px;
  color: #aaa;
  margin-bottom: 20px;
  line-height: 1.6;
}

.watch-btn {
  display: inline-block;
  background-color: transparent;
  color: #ff0000;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #ff0000;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.watch-btn:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.load-more {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 40px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.load-more:hover {
  background-color: #ffffff;
  color: #000000;
}

.loading {
  text-align: center;
  font-size: 18px;
  margin-top: 40px;
  color: #888;
}

@media (max-width: 768px) {
  .subscribe-button-wrapper {
    display: none; /* Hide the subscribe button on mobile devices */
  }

  h1 {
    font-size: 28px;
  }

  .tabs button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .episode-item h3 {
    font-size: 20px;
  }

  .episode-item p {
    font-size: 14px;
  }
}

/* Add these styles to your existing App.css file */

.youtube-video-list {
  margin-top: 30px;
}

.youtube-video-list .header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically centers the items */
  padding: 10px 0; /* Adjust padding as needed */
}

.youtube-video-list .tabs {
  display: flex;
  align-items: center; /* Vertically centers the tabs */
}

.youtube-video-list .subscribe-btn {
  margin-left: auto;
  padding: 10px 20px; /* Ensure consistent padding */
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.video-item {
  display: block;
  text-decoration: none;
  color: inherit;
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.video-item img {
  width: 100%;
  height: auto;
  display: block;
}

.video-item h3 {
  font-size: 16px;
  margin: 10px;
  color: #ffffff;
}

.video-item p {
  font-size: 14px;
  margin: 10px;
  color: #aaaaaa;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-item a {
  display: inline-block;
  background-color: #ff0000;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.video-item a:hover {
  background-color: #cc0000;
}

button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #cc0000;
}

button:disabled {
  background-color: #444444;
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (min-width: 1024px) {
  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (min-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

/* Add this to your App.css file */
main h1 {
  text-align: left;
}

/* Add this to your App.css file */
main h1 {
  font-family: 'Impact', sans-serif;
  font-size: 48px; /* Adjust the size as needed */
}

.posts-container {
  display: flex;
  gap: 20px; /* Optional: adds space between the posts */
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.substack-post-embed {
  flex: 1; /* Optional: makes each post take equal space */
  max-height: 550px; /* Set the maximum height for each post */
  overflow: hidden; /* Adds scrollbars if content exceeds max height */
  min-width: 250px; /* Ensures a minimum width for each post */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .posts-container {
    flex-direction: column; /* Stacks posts vertically */
  }
}

.nav-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-right: 20px;
}

.logo {
  width: 40px; /* Adjust the size as needed */
  height: auto;
}
